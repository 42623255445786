import React from 'react';
import {Title, Text, Button} from "gatsby-theme-material-foundry"
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import {Box, Tabs, Tab, Grid} from '@material-ui/core';
import Img from "gatsby-image"
import useMediaQuery from '@material-ui/core/useMediaQuery';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
    
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: "300px",
  },
  root2: {
    flexGrow: 1,
  },
  tabs: {
      minWidth: "200px",
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function VerticalTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if(useMediaQuery('(min-width:800px)')){
  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
          {props.items.map((item) =>    <Tab label={item.title} {...a11yProps(item.id)} />)}
     
  
      </Tabs>
      {props.items.map((item) =>          
      <TabPanel value={value} index={item.id}>
          <Grid container spacing={2}>
              {item.image && <Grid item xs={12} sm={4}>
           <Img fluid={item.image} />
           </Grid>}
           <Grid item xs={12} sm={8}>
      <Title>{item.title}</Title>
      <Text>
        {item.text}
      </Text>
      {item.link && <Button color="black" to={item.link}>Les mer</Button>}
      </Grid>
      </Grid>
      </TabPanel>)}
     
     
    </div>
  )} else {
    return (
      <div className={classes.root2}>
        <Tabs
          variant="scrollable"
          orientation="horizontal"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          centered
        >
            {props.items.map((item) =>    <Tab label={item.title} {...a11yProps(item.id)} />)}
       
    
        </Tabs>
        {props.items.map((item) =>          
        <TabPanel value={value} index={item.id}>
            <Grid container spacing={2}>
                {item.image && <Grid item xs={12} sm={4}>
             <Img fluid={item.image} />
             </Grid>}
             <Grid item xs={12} sm={8}>
        <Title>{item.title}</Title>
        <Text>
          {item.text}
        </Text>
        {item.link && <Button color="black" to={item.link}>Les mer</Button>}
        </Grid>
        </Grid>
        </TabPanel>)}
       
       
      </div>
    )
  }
}

export const InfrastrukturTabs = (item) => (
  <Grid container spacing={2}>
  {item.image && <Grid item xs={12} sm={4}>
<Img fluid={item.image} />
</Grid>}
<Grid item xs={12} sm={8}>
<Title>{item.title}</Title>
<Text>
{item.text}
</Text>
{item.link && <Button color="black" to={item.link}>Les mer</Button>}
</Grid>
</Grid>
)