import React from "react"
import { graphql } from "gatsby"
import { navigate } from '@reach/router';
import { makeStyles, Container, Box } from "@material-ui/core"
import {
  Title,
  Text,
  DividedSection,
  Button,
} from "gatsby-theme-material-foundry"
import Layout from "../components/layout"
import SEO from "../components/SEO/seo"
import Tabs from "../components/vertical-tabs"

const useStyles = makeStyles(theme => ({
  wavesvg: {
    position: "relative",
    left: "0",
  },
  cards: {
    backgroundColor: theme.palette.black.main,
  },
  hovered: {
    backgroundColor: theme.palette.black.light,
  },
  aboutContainer: {
    color: "white",
  },
  greenSection: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "150px",
      paddingBottom: "50px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "250px",
      paddingBottom: "100px",
    },
  },
  imgCard: {
    borderRadius: "6px !important",
    boxShadow:
      "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    maxWidth: "100%",
    height: "auto",
  },
  avatar: {
    margin: 10,
  },
  avatarname: {
    margin: "5px",
    padding: "0",
  },
  bigAvatar: {
    width: 100,
    height: 100,
    marginRight: "24px",
  },
  title: {
    margin: "0",
    padding: "0",
  },
  subtitle: {
    fontWeight: "600",
  },
}))

function AboutPage({ data }) {
  const classes = useStyles()
  const fordeImg = data.forde.image.childImageSharp.fluid
  const bergenImg = data.bergen.image.childImageSharp.fluid
  const sogndalimg = data.sogndal.image.childImageSharp.fluid
  return (
    <Layout dark>
      <SEO title="HVL Skape tjenester" slug="tjenester" />

      <DividedSection className={classes.greenSection}>
        <Container maxWidth="md">
          <Box mb={12} align="center">
            <Title variant="h2" primary align="center">
              Tjenester
            </Title>
            <Text black variant="h5" className={classes.subtitle}>
              HVL Skape tilbyr tjenester til studentene på alle HVL sine campus
            </Text>
          </Box>
          <Title variant="h3" black align="left">
            Veiledning
          </Title>
          <Text>
            Har du en idé du ønsker å utvikle? HVL Skape tilbyr
            veiledningstjenester for studenter som er i tidlig fase i
            idéutviklingsprosessen. Vår samlede kompetanse og erfaring med
            innovasjonsprosesser og oppstartsvirksomheter vil gi deg et godt
            grunnlag for å arbeide videre med din idé. I tillegg sitter vi på et
            bredt nettverk innen en rekke bransjer og næringer og som vi kan
            koble på hvor det er relevant.
          </Text>
           <Button color="black" size="lg" onClick={() => navigate("/om-oss#kontakt-oss")}>
            Kontakt veiledere
          </Button>
          <Box my={12}>
            <Title variant="h3" black align="left">
              Infrastruktur
            </Title>

            <Tabs
              items={[
                {
                  id: 0,
                  image: bergenImg,
                  title: "Fabrikken studentinkubator",
                  text:
                    "Fabrikken: Studentinkubator Fabrikken er Høgskulen på Vestlandet (HVL) sin studentinkubator ved Campus Bergen. Fabrikken er en gratis kontorplass og arbeidslokale for studentgrundere ved HVL. Lokalet blir også benyttet som en møte- og inspirasjonsplass for både studenter og ansatte hvor det arrangeres diverse kurs, arrangementer og seminar",
                },
                {
                  id: 1,
                  image: fordeImg,
                  title: "Campus Førde Verftet",
                  text:
                    "Høgskulen på Vestlandet, Fagskolen og Sogn og Fjordane Fylkeskommune investerer i utviklingen av undervisnings- og lab-fasiliteter på Campus Førde Verftet. I denne tilknytningen jobber vi med å få et areal hvor studenter kan få mulighet til å jobbe med sine innovasjons- og entreprenørskapsprosjekt. ",
                },
                {
                  id: 2,
                  image: sogndalimg,
                  title: "Studentinkubator Sogndal",
                  text:
                    "Kunnskapsparken Sogn og Fjordane tilbyr studentinkubatorplass i sine lokaler rett ved HVL Campus Sogndal. Ta kontakt med Rune Njøs (HVL) eller direkte med Kunnskapsparken v. Roger Ulvestad. ",
                },
              ]}
            />
          </Box>

          <Title variant="h3" black align="left">
            Finansiering
          </Title>
          <Text>
            Som student med en forretningsidé har du mulighet til å søke om
            midler fra Framostipendet og BKK-bidraget gjennom HVL Skape. I
            tillegg mobiliserer vi til Norges Forskningsråds stipendordning for
            studentgründere STUD-ENT.
          </Text>
          <Button color="black" size="lg" to="/finansiering">
            Gå til Finansiering
          </Button>
        </Container>
      </DividedSection>
    </Layout>
  )
}
export const ItemPageQuery = graphql`
  query Tjenester {
    sogndal: campus(name: { eq: "Sogndal" }) {
      name
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    bergen: campus(name: { eq: "Bergen" }) {
      name
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    forde: campus(name: { eq: "Førde" }) {
      name
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

export default AboutPage
